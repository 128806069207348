
@import '@ng-matero/extensions/prebuilt-themes/azure-blue.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

.mat-datepicker-content-container{
    background-color: #fff !important;
  }

  .model_block{
    padding: 20px;
    position: relative;
}
.model_block  h2{
    font-size: 22px;
}
.model_block p{
    color: #676767;
}
.close_btnmodel{
    position: absolute;
    right: 20px;
    top: 20px;
}
.close_btnmodel button{
    padding: 10px;
    border-radius: 50%;
    border: none;
    outline: none;
    background: #000;
    color: #fff;
    width: 35px;
    height: 35px;
}
.selectfieldbox mat-form-field{
  width: 100%;
}
.mat-mdc-tab-body-content{
  overflow: unset !important;
}
.mat-mdc-tab-body.mat-mdc-tab-body-active{
  overflow: unset !important;

}
.close_btnmodel button{
  cursor:pointer;
}

.sidebar-menu .active {
  font-weight: bold;

  background-color:rgb(25 84 149) !important
}
.sidebar-menu .active .mdc-list-item__content .mdc-list-item__primary-text {
  color: #fff !important;
}
.sidebar-menu .active .sidebaricon svg path{
  fill:#fff !important
}
.uploadbtnactivebtn .mdc-radio{
  position: absolute !important;
}

// .status_appeal .mat-mdc-form-field-subscript-wrapper{
//   display: none !important;
// }


ngx-material-timepicker-container{
  position: absolute !important;
  z-index: 9999 !important;
}
.upload-doc-section {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 0px 15px 0px;

  @media (max-width: 991px) {
    margin: 0;
    margin-left: auto;
    margin-bottom: -1px;
  }

  @media (max-width: 768px) {
    margin: 0;
  }

  @media (max-width: 576px) {
    flex-wrap: wrap;
    width: 100%;
  }

  .radio-btn {
    position: relative;
    margin: 0 0 0 20px;

    label {
      display: flex;
      align-items: center;
      position: relative;
      padding: 15px 20px 15px 45px;
      margin: 0 0 0 0px;
      border-right: 1px solid #cbcbcb;
      border-left: 1px solid #cbcbcb;
      border-top: 1px solid #cbcbcb;
      border-bottom: 1px solid #cbcbcb;
      border-top-left-radius: 13px;
      border-top-right-radius: 13px;
      border-bottom-right-radius: 13px;
      border-bottom-left-radius: 13px;
      margin-right:15px
      
    }

    input {
      position: absolute;
      left: 15px;
      top: 49%;
      transform: translate(0%, -50%);
      border: 1px solid #000000;
      width: 20px;
      height: 20px;
      z-index: 1;

      &:checked {
        &~label {
          border-color: #ffffff !important;
          background-color: #ffdc4a !important;
        }
      }
    }

    @media (max-width: 768px) {
      padding: 13px 15px 13px 15px;
      border: 1px solid #cbcbcb;
      border-radius: 10px;
      margin: 0 0 10px 10px;
    }

    @media (max-width: 576px) {
      width: 100%;
      margin: 0 0 10px 0;
    }
  }
}
.upload-doc-conten {
  position: relative;
  width: 100%;
  padding: 30px 0 30px 0;
  border-bottom: 1px solid #cbcbcb;

  @media (max-width:768px) {
    padding: 20px 0 20px 0;
  }

  .doc-titles {
    position: relative;
    width: 100%;
    margin: 0 0 20px 0;

    h3 {
      font-size: 2rem;
      font-weight: 500;
      margin: 0 0 0 0;

      @media (max-width:576px) {
        font-size: 1.8rem;
      }
    }
  }

  .application_doc_sec {
    position: relative;
    width: 100%;
  }

  .doc-row {
    display: flex;
    align-items: flex-start;
    width: 100%;
    position: relative;

    @media (max-width:991px) {
      flex-wrap: wrap;
    }

    @media (max-width:420px) {
      margin: 0 0 10px 0;
    }

    .upload_isec {
      // width: 30%;
      padding: 0 30px 0 0;
      position: relative;

      @media (max-width:768px) {
        width: calc(100% - 110px);
        padding: 0 20px 0 0;
      }

      @media (max-width:420px) {
        width: 100%;
        padding: 0 20px 0 0;
      }
    }

    .upload-btns {
      @media (max-width:768px) {
        position: absolute;
        right: 0;
        top: 0;
      }

      @media (max-width:420px) {
        position: relative;
        width: 100%;
      }

      .btn {
        margin: 25px 0 0 0;

        @media (max-width:991px) {
          margin: 30px 0 0 0;
        }

        @media (max-width:420px) {
          width: 100%;
          margin: -5px 0 0 0px;
        }
      }
    }

    .upload-imgsec {
      // width: 60%;
      position: relative;
      flex: 2;
      padding: 0 0 0 30px;
      margin: 0 0 0 30px;
      border-left: solid 0.1rem #cbcbcb;

      @media (max-width:991px) {
        width: 100%;
        position: relative;
        padding: 0;
        margin: 0;
        border-left: 0;
      }

      .upload-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        @media (max-width:991px) {
          margin: 0 0 10px 0;
        }

        @media (max-width:768px) {
          width: 100%;
        }

        @media (max-width:420px) {
          margin: 10px 0 10px 0;
        }

        .document-section {
          padding: 10px 10px 10px 10px;
          border-radius: 8px;
          background: rgba(1, 78, 255, 0.1);
          border: solid 0.1rem #014eff;
          width: calc(50% - 20px);
          margin: 10px 10px;

          .initial {
            width: 90px;
            height: 50px;
            background: white;
            margin: 0 15px 0 0;
          }

          .media h4 {
            font-size: 1.6rem;
            font-weight: 600;
          }
        }
      }
    }
  }
  .admin_edit_comments {
    /* background: #0887fc; */
    width: 30px;
    height: 30px;
    border-radius: 8px;
    text-align: center;
    position: absolute;
    top: 8px;
    right: 10px;
}

}

/* background colors */

.mat-bg-red {
    background-color: #f44c3e !important;
}

.mat-bg-pink {
    background-color: #ee226e !important;
}

.mat-bg-purple {
    background-color: #a52db7 !important;
}

.mat-bg-deep-purple {
    background-color: #7343be !important;
}

.mat-bg-indigo {
    background-color: #495bbd !important;
}

.mat-bg-blue {
    background-color: #25a0f4 !important;
}

.mat-bg-light-blue {
    background-color: #03b3f5 !important;
}

.mat-bg-cyne {
    background-color: #01c3da !important;
}

.mat-bg-teal {
    background-color: #00a294 !important;
}

.mat-bg-green {
    background-color: #57b85c !important;
}

.mat-bg-amber {
    background-color: #ffc901 !important;
}

.mat-bg-yellow {
    background-color: #ffed44 !important;
}

.mat-bg-orange {
    background-color: #fe6225 !important;
}

.mat-bg-accent {
    background-color: #ff4081 !important;
}

.mat-bg-brown {
    background-color: #845f53 !important;
}

.mat-bg-blue-grey {
    background-color: #6b8897 !important;
}

.mat-bg-dark {
    background-color: #000 !important;
}

.mat-bg-white {
    background-color: #fff !important;
}

/* social bg colors */

.bg-facebook {
    background-color: #3b5998 !important;
}

.bg-twitter {
    background-color: #55acee !important;
}

.bg-google {
    background-color: #e52d27 !important;
}

.bg-linkedin {
    background-color: #0976b4 !important;
}


/* light bg colors */

.mat-light-bg-red {
    background-color: rgba(244, 77, 62, 0.1) !important;
}

.mat-light-bg-pink {
    background-color: rgba(238, 34, 109, 0.1) !important;
}

.mat-light-bg-purple {
    background-color: rgba(165, 45, 183, 0.1) !important;
}

.mat-light-bg-deep-purple {
    background-color: rgba(114, 67, 190, 0.1) !important;
}

.mat-light-bg-indigo {
    background-color: rgba(73, 90, 189, 0.1) !important;
}

.mat-light-bg-blue {
    background-color: rgba(37, 161, 244, 0.1) !important;
}

.mat-light-bg-light-blue {
    background-color: rgba(3, 180, 245, 0.1) !important;
}

.mat-light-bg-cyne {
    background-color: rgba(1, 196, 218, 0.1) !important;
}

.mat-light-bg-teal {
    background-color: rgba(0, 162, 148, 0.1) !important;
}

.mat-light-bg-green {
    background-color: rgba(87, 184, 92, 0.1) !important;
}

.mat-light-bg-amber {
    background-color: rgba(255, 200, 1, 0.1) !important;
}

.mat-light-bg-yellow {
    background-color: rgba(255, 236, 68, 0.1) !important;
}

.mat-light-bg-orange {
    background-color: rgba(254, 99, 37, 0.1) !important;
}

.mat-light-bg-accent {
    background-color: rgba(255, 64, 128, 0.1) !important;
}

.mat-light-bg-brown {
    background-color: rgba(132, 95, 83, 0.1) !important;
}

.mat-light-bg-blue-grey {
    background-color: rgba(107, 136, 151, 0.1) !important;
}

.mat-light-bg-dark {
    background-color: rgba(0, 0, 0, 0.1) !important;
}



/* text colors */

.text-red {
    color: #f44c3e !important;
}

.text-pink {
    color: #ee226e !important;
}

.text-purple {
    color: #a52db7 !important;
}

.text-deep-purple {
    color: #7343be !important;
}

.text-indigo {
    color: #495bbd !important;
}

.text-blue {
    color: #25a0f4 !important;
}

.text-light-blue {
    color: #03b3f5 !important;
}

.text-cyne {
    color: #01c3da !important;
}

.text-teal {
    color: #00a294 !important;
}

.text-green {
    color: #57b85c !important;
}

.text-amber {
    color: #ffc901 !important;
}

.text-yellow {
    color: #ffed44 !important;
}

.text-orange {
    color: #fe6225 !important;
}

.text-accent {
    color: #ff4081 !important;
}

.text-brown {
    color: #845f53 !important;
}

.text-blue-grey {
    color: #6b8897 !important;
}

.text-dark {
    color: #000 !important;
}

.text-grey {
    color: #e1e1e1 !important;
}

.text-white {
    color: #fff !important;
}



/* borders */

.border-red {
    border-color: #f44c3e !important;
}

.border-pink {
    border-color: #ee226e !important;
}

.border-purple {
    border-color: #a52db7 !important;
}

.border-deep-purple {
    background-color: #7343be !important;
}

.border-indigo {
    border-color: #495bbd !important;
}

.border-blue {
    border-color: #25a0f4 !important;
}

.border-light-blue {
    border-color: #03b3f5 !important;
}

.border-cyne {
    border-color: #01c3da !important;
}

.border-teal {
    border-color: #00a294 !important;
}

.border-green {
    border-color: #57b85c !important;
}

.border-amber {
    border-color: #ffc901 !important;
}

.border-yellow {
    border-color: #ffed44 !important;
}

.border-orange {
    border-color: #fe6225 !important;
}

.border-accent {
    border-color: #ff4081 !important;
}

.border-brown {
    border-color: #845f53 !important;
}

.border-blue-grey {
    border-color: #6b8897 !important;
}

.border-dark {
    border-color: #000 !important;
}

.border-white {
    border-color: #fff !important;
}

.table-responsive {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}